html {
    height: 100%;
}

body {
    background-color: #3d3e3f !important;
    font-weight: 500;
    color: #212529;
    height: 100%;
    overflow: hidden;
    font-family: 'Mulish', sans-serif !important;
}

#app {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #fff;
    height: 100%;
}

.app-header {
    display: flex;
    height: 55px;
    background-color: #13294B;
    padding: 15px 20px;
    color: #FFFFFF;
    z-index: 2;
    position: relative;
}

.app-header .company-name {
    font-size: 16px;
    height: 25px;
    font-weight: 500;
    margin: 0px !important;
}

.app-header .company-logo img {
    max-width: 120px;
    cursor: pointer;
    max-height: 25px;
}

.select-control {
    margin: auto;
    margin-left: 0px;
}

.select-control>.dropdown {
    width: max-content;
}

.scrollable-menu {
    height: auto;
    max-height: 400px;
    overflow-x: hidden;
    min-width: 6rem;
    display: inline !important;
    font-weight: 500;
}

.sapphire-color {
    color: #0973BA !important;
}

.navy-blue-color {
    color: #05386B !important;
}

.error-text-color {
    color: #CC4A43;
}

.black-text-color {
    color: #212529;
}

.gray-text-color {
    color: #565A5E;
}


/* /////////////////////////////////Font Sizes////////////////////////////// */

.font14 {
    font-size: 14px;
}

.font16 {
    font-size: 16px;
}

.font18 {
    font-size: 18px;
}

.font20 {
    font-size: 20px;
}

.font24 {
    font-size: 24px;
}


/* /////////////////////////////////Font Weights////////////////////////////// */

.fontWeight400 {
    font-weight: 400;
}

.fontWeight500 {
    font-weight: 500;
}

.fontWeight600 {
    font-weight: 600;
}

.fontWeight700 {
    font-weight: 700;
}

.custom-control-input:checked~.custom-control-label::before,
custom-control:focus {
    color: #fff;
    border-color: #8bc656;
    background-color: #8bc656;
}

custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: #fff;
    border: #8bc656 solid 1px;
}


/*-------------Font Face -------------*/

@font-face {
    font-family: 'Journal';
    src: url('../fonts/journal.eot');
    src: url('../fonts/journal.eot?#iefix') format('embedded-opentype'), url('../fonts/journal.woff') format('woff'), url('../fonts/journal.ttf') format('truetype'), url('../fonts/journal.svg#JournalRegular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Mulish';
    src: url('../fonts/Mulish-Regular.eot') format('embedded-opentype'), url('../fonts/Mulish-Regular.ttf') format('truetype'), url('../fonts/Mulish-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.no-padding-left {
    padding-left: 0px !important;
}

.no-padding-right {
    padding-right: 0px !important;
}

.no-padding-top {
    padding-top: 0px !important;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.no-padding {
    padding: 0px 0px 0px 0px !important;
}

.btn-primary-extended {
    background-color: #88c656 !important;
    border-radius: 50px !important;
    min-width: 150px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border: 1px solid #88c656 !important;
}

.btn-danger-extended {
    background-color: #dc3545 !important;
    border-radius: 50px !important;
    min-width: 150px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border: 1px solid #dc3545 !important;
}

.content-wrapper-full {
    padding: 0px 98px;
    margin: 20px 98px;
    background-color: #fff;
    min-height: 560px;
    border-radius: 5px;
    position: relative;
}

.content-wrapper-full .greeting {
    padding-top: 50px;
    padding-bottom: 60px;
    font-size: 22px;
    font-family: 'Myriad Pro';
}

.content-wrapper-full .greeting {
    padding-top: 50px;
    padding-bottom: 60px;
    font-size: 22px;
    font-family: 'Myriad Pro';
}

.content-wrapper-full .greeting,
.content-wrapper-full .explanation,
.content-wrapper-full .instruction,
.content-wrapper-full .authentication {
    text-align: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    word-break: break-word;
}

.text-left {
    text-align: left
}

.text-center {
    text-align: center
}

.unavailable-icon {
    margin: auto;
    width: 130px;
    padding-bottom: 20px;
}

.common-pages-main-header {
    font-size: 18px;
    font-weight: 700;
    line-height: 22px;
}

.common-pages-sub-header {
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
}

.link-email {
    color: #007bff !important
}

.link-email:hover {
    cursor: pointer !important;
    text-decoration: underline !important
}

.logout-btn-container {
    position: absolute;
    top: 12px;
    right: 10px;
}

.logout-btn-container>.btn {
    background-color: #13294B;
    margin-right: 5px;
    font-weight: 500;
    font-size: 16px;
    border: none;
    color: #FFFFFF;
    outline: none;
    box-shadow: none;
}

.logout-btn-container>.btn:active {
    background-color: #13294B !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
}

.logout-btn-container button:focus {
    outline: black auto 5px !important;
    outline-offset: 1px;
}

.app-header .contact-person-header {
    position: absolute;
    right: 150px;
    line-height: 0;
    margin-top: -2px;
    cursor: pointer;
}

.app-header .contact-person-header>a {
    color: white;
    text-decoration: none;
}

#contact-info-popover {
    font-family: 'Mulish', sans-serif;
}

#contact-info-popover .contact-info-body {
    padding: 10px;
}

#contact-info-popover .contact-info-body label {
    font-weight: 800;
    margin: 0;
    display: inline;
}

#contact-info-popover .contact-info-body .mail a {
    color: #007bff;
    text-decoration: none;
    font-weight: 800;
}

#contact-info-popover .contact-info-header {
    background-color: #f7f7f7;
    font-weight: 800;
    padding: 5px 10px;
    box-shadow: 0px 1px lightgray;
}

#contactInfoPopover .contact-info-content {
    padding: 0px;
}


/* Initial application loading icon - START */

.loader,
.loader:before,
.loader:after {
    background: #ffffff;
    -webkit-animation: load1 1s infinite ease-in-out;
    animation: load1 1s infinite ease-in-out;
    width: 1em;
    height: 4em;
}

.loader {
    color: #ffffff;
    text-indent: -9999em;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 11px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
}

.loader:before,
.loader:after {
    position: absolute;
    top: 0;
    content: '';
}

.loader:before {
    left: -1.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
}

.loader:after {
    left: 1.5em;
}

@-webkit-keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}

@keyframes load1 {
    0%,
    80%,
    100% {
        box-shadow: 0 0;
        height: 4em;
    }
    40% {
        box-shadow: 0 -2em;
        height: 5em;
    }
}


/* Initial application loading icon - END */

.col-lg-offset-3 {
    margin-left: 25%;
}

.align-right {
    text-align: right !important
}

.btn-white,
.btn-default {
    background: #FAFAFA !important;
    border: 1px #e0e0e0 solid;
    color: #303641 !important;
    margin-right: 2px;
    margin-bottom: 2px;
}

._loading_overlay_wrapper {
    height: 100vh;
    background-color: #fff;
    overflow-y: auto;
}

.modal-header {
    border-top: 0px solid #DEE2E6;
    border-right: 0px solid #DEE2E6;
    border-bottom: 1px solid #DEE2E6;
    border-left: 0px solid #DEE2E6;
    padding: 16px;
    gap: 8px;
}

.modal-footer {
    border-top: 1px solid #DEE2E6;
    border-right: 0px solid #DEE2E6;
    border-bottom: 0px solid #DEE2E6;
    border-left: 0px solid #DEE2E6;
    padding: 12px;
    gap: 8px;
}

.modal-header .modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.modal-header .close {
    font-size: 32px;
    opacity: 0.5;
    line-height: 1rem;
}

.modalIcon {
    color: grey;
    margin-right: 10px;
    vertical-align: text-bottom;
    font-size: 20px !important;
}

.btn.disabled,
.btn:disabled {
    cursor: not-allowed !important;
}

#exampleModalLongTitle {
    display: inline-flex;
}

#exampleModalLongTitle+span {
    cursor: pointer;
}

@media (min-width: 300px) and (max-width: 800px) {
    #exampleModalLongTitle+span {
        padding-left: 0px;
    }
}

.btn-assign-to-delegatee {
    float: left;
    margin-left: 60%;
}

.btn-assign-to-delegatee-color {
    background-color: #0A72BB !important;
    border: 1px solid #0A72BB !important;
}

.modal-assign-to-someone {
    width: 140%;
    margin-left: -190px;
}

#delegatee .modal-content {
    width: 130%;
    display: inherit;
    margin: 0px -50px;
}

#delegatee .btn-cancel,
.decline-modal .btn-cancel {
    width: 100px;
    height: 38px;
    padding: 6px 12px 6px 12px;
    border-radius: 3px !important;
    border: 1px solid #898D91 !important;
    background: #F0F1F1;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

#delegatee .btn-primary,
.decline-modal .btn-danger {
    width: 100px;
    height: 38px;
    padding: 6px 12px 6px 12px;
    border-radius: 3px !important;
    gap: 8px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
}

#delegatee .btn-primary {
    border: 1px solid #0973ba !important;
    background: #0973ba !important;
}

.decline-modal .btn-danger {
    border: 1px solid #CC4A43 !important;
    background: #CC4A43 !important;
}

#delegatee .label {
    height: 21px;
    padding: 0px 0px 4px 0px;
    font-weight: 700;
}

.modal-description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    margin: 0px 0px 15px 0px !important;
    color: #000000;
}

.modal-footer .bootbox-cancel {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d;
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
    min-width: 58px !important;
    border-radius: 0px !important;
}

.delegatee-compulsory-field {
    color: #c50505;
    position: absolute;
    top: 3px;
    font-size: 16px;
}

.glyphicon-asterisk:before {
    content: "*";
}

.glyphicon {
    display: inline-block;
    font-family: Glyphicons Halflings;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
}

.compulsory-star {
    color: black;
    padding: 5px;
}

.delegatee-textbox {
    height: 30px;
    margin-top: 8px;
    margin-bottom: 20px;
}

.reasonForTextArea {
    min-height: 132px;
    width: 100%;
    margin: 8px 0px 8px 0px;
    font-size: inherit;
    border: 1px solid #898D91;
    padding: 0px 5px 0px 5px;
    border-radius: 4px !important;
    &::placeholder {
        font-style: italic;
        padding: 0px 2px 0px 0px;
    }
}

.delegatee-input-field {
    height: 30px;
    font-size: 14px;
    padding: 4px 8px 4px 8px;
    border: 1px solid #898D91;
    border-radius: 4px !important;
}

#delegatee .sub-header {
    font-weight: 400;
    font-size: 13px;
}

.description {
    margin: 20px;
    font-size: small;
}

.signedSuccessfullMessageLine1 {
    font-weight: 700;
    font-size: x-large;
}

.signedSuccessfullMessageLine2 {
    font-size: smaller;
    text-align: left !important;
}

.btn-delegate {
    margin-right: 90px !important;
}

@media (min-width: 550px) and (max-width: 640px) {
    .btn-delegate {
        margin-right: 10px !important;
    }
}

@media (max-width: 550px) {
    .btn-delegate {
        margin-right: 0px !important;
    }
}

.notification-page {
    width: 95%;
    margin: auto;
    max-width: 430px;
    margin-top: 4%;
    overflow: auto;
    padding-bottom: 40px;
    font-size: 18px;
}

.page-container {
    background-color: white;
    border-style: ridge;
    border-radius: 5px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.btn-proceed-mobile {
    background-color: #0A72BB !important;
    border: 1px solid #0A72BB !important;
    min-width: 150px !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    border-radius: 50px !important;
}

.dropdown-toggle.btn.btn-success {
    color: var(--control-border-color);
    background-color: #f3f9ee;
    border: 2px solid #8bc656;
    border-radius: 4px !important;
    width: 73px;
    text-align: left;
    font-weight: 500;
}

.dropdown-toggle.btn.btn-success::after {
    position: absolute;
    right: 15px;
    top: 18px;
}

.delegatee-overlay {
    height: auto;
}