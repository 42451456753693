.login-page {
    padding-left: 15% !important;
    padding-right: 15% !important;
    padding-top: 3% !important;
    background-color: white;
    height: 90vh;
    overflow: auto;
}

.invalid-page {
    padding-top: 6% !important;
    background-color: white;
    height: 90vh;
    overflow: auto;
    margin: auto;
    max-width: 544px;
}

.btn-login {
    margin: 5px !important;
}

.shepherd-element[data-popper-placement='left'] button.shepherd-button:after,
.shepherd-element[data-popper-placement='right'] button.shepherd-button:before {
    pointer-events: none;
}

.shepherd-element {
    z-index: 1039 !important;
    position: absolute !important;
}

.shepherd-element[data-popper-reference-hidden]:not(.shepherd-centered) {
    visibility: hidden;
}

footer {
    height: 50px;
    background: white !important;
    border: 1px solid #bfbfbf;
    padding: 5px;
}

main {
    flex: 1;
    /*height: 100px;*/
}

#container {
    height: 100% !important;
}

.pdf-preview-container {
    height: 100% !important;
    min-height: 0 !important;
    /* overflow: auto; */
}

.btn-decline {
    position: absolute;
    right: 158px;
    width: 122px;
    height: 38px;
}

.btn-finish {
    float: right;
    width: 122px;
    font-weight: 700 !important;
    font-size: 16px !important;
    height: 38px;
    background-color: #5c8001 !important;
    border: 1px solid #5c8001 !important;
    color: #FFFFFF !important;
}

.btn-delegate {
    float: right;
    margin-right: 10px;
}

.main {
    height: calc(100vh - 267px) !important;
}

.footerContainer {
    height: 80px;
    background: white !important;
    border: 1px solid #bfbfbf;
    position: fixed;
    bottom: 25px;
    width: 100%;
    z-index: 1040;
    padding: 20px 25px;
}

@media (max-width: 550px) {
    .footerContainer {
        text-align: center;
    }
    .footerContainer a {
        width: 60%;
        float: none;
        margin-right: 0px;
    }
    .footerContainer button {
        width: 60%;
        float: none;
        padding: 4px;
        margin: 2px !important;
    }
    footer {
        height: 120px;
    }
    .main {
        height: calc(100vh - 212px) !important;
    }
}

.file-list-tree-view-container {
    margin: 12px;
}

.side-panel {
    height: calc(100vh - 200px) !important;
}

.rct-node-icon {
    color: #008af8 !important;
}

li span.rct-text span.rct-node-clickable {
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    white-space: nowrap;
    width: 160px !important;
}

.rct-title {
    overflow: hidden;
    width: 150px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline;
}

.rct-checkbox {
    display: none;
}

.rct-disabled {
    opacity: .75;
    cursor: not-allowed
}

.rct-disabled>.rct-text>.rct-node-clickable {
    opacity: .75;
    cursor: not-allowed
}

.rct-disabled>.rct-text>.rct-node-clickable:hover {
    background: 0 0
}

.file-node-selected {
    outline: 0;
    background: rgba(51, 51, 204, 0.2);
}

.rct-node-clickable:focus {
    background: 0 0
}

.download-btn {
    margin-top: 3%;
}

.textbox-control {
    background-color: white !important
}

.YearMonthDay.col {
    display: inline-flex;
}

.checkmark {
    position: absolute;
    top: 15px;
    left: 0px;
    height: 23px;
    width: 23px;
    background-color: #eee;
    border: 3px solid var(--control-border-color);
    border-radius: 4px !important;
}

.page-viewer {
    padding-bottom: 40px;
}

.document-control {
    z-index: 1;
}

.shepherd-element[data-shepherd-step-id="btnContinue"] {
    z-index: 1040 !important;
}

@media (max-width: 481px) {
    .shepherd-element-left[data-popper-placement="right"] {
        margin-left: 12px !important;
        margin-right: 0px !important;
    }
    .shepherd-element-right[data-popper-placement="left"] {
        margin-right: 12px !important;
        margin-left: 0px !important;
    }
    .shepherd-element-left[data-popper-placement="right"] button.shepherd-button:after {
        left: -83% !important;
        border: 10px solid transparent !important;
        border-right: 12px solid #FF9800 !important;
    }
    .shepherd-element-right[data-popper-placement="left"] button.shepherd-button:before {
        right: -83% !important;
        border: 10px solid transparent !important;
        border-left: 12px solid #FF9800 !important;
    }
    .shepherd-element-left {
        margin-right: 12px !important;
    }
    .shepherd-element-right {
        margin-right: 12px !important;
    }
}

@media (min-width:482px) and (max-width: 960px) {
    .shepherd-element-left[data-popper-placement="right"] {
        margin-left: 14px !important;
        margin-right: 0px !important;
    }
    .shepherd-element-right[data-popper-placement="left"] {
        margin-right: 14px !important;
        margin-left: 0px !important;
    }
    .shepherd-element-left[data-popper-placement="right"] button.shepherd-button:after {
        left: -29px !important;
        border: 15px solid transparent !important;
        border-right: 15px solid #FF9800 !important;
    }
    .shepherd-element-right[data-popper-placement="left"] button.shepherd-button:before {
        right: -29px !important;
        border: 15px solid transparent !important;
        border-left: 15px solid #FF9800 !important;
    }
    .shepherd-element-left {
        margin-right: 14px !important;
    }
    .shepherd-element-right {
        margin-right: 14px !important;
    }
}

@media (min-width:961px) {
    .shepherd-element-left[data-popper-placement="right"] {
        margin-left: 18px !important;
        margin-right: 0px !important;
    }
    .shepherd-element-right[data-popper-placement="left"] {
        margin-right: 18px !important;
        margin-left: 0px !important;
    }
    .shepherd-element-left[data-popper-placement="right"] button.shepherd-button:after {
        left: -39px;
        border: 20px solid transparent !important;
        border-right: 20px solid #FF9800 !important;
    }
    .shepherd-element-right[data-popper-placement="left"] button.shepherd-button:before {
        right: -39px;
        border: 20px solid transparent !important;
        border-left: 20px solid #FF9800 !important;
    }
}

@media (min-width: 576px) {
    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem);
    }
}

@media only screen and (min-device-width: 712px) and (max-device-width: 1366px) and (-webkit-min-device-pixel-ratio: 2) {
    .offset-sm-9 {
        margin-left: 55%;
    }
    .side-panel {
        background-color: #ffffff !important;
        width: 330px;
        transition: width .2s, height .2s;
        position: unset;
    }
    .right-section {
        display: flex;
        flex-direction: row-reverse;
    }
    main {
        background: #e0e0e0;
    }
    .page-viewer {
        height: calc(100vh - 200px);
    }
    #delegatee .modal-content {
        margin-left: -75px;
    }
}

.toastify-container {
    width: 400px !important;
    margin-right: 9px;
    margin-top: 47px;
}

.toastify-body {
    border-radius: 4px !important;
    color: black;
}

.toastify-error {
    background: #FAEDEC !important;
    border-left: 5px solid #B8433C;
}

.toastify-warning {
    background: #FBF5EB !important;
    border-left: 5px solid #D69F38;
}

.toastify-success {
    background: #E9F3ED !important;
    border-left: 5px solid #1F8747;
}

.toastify-info {
    background: #E6F1F8 !important;
    border-left: 5px solid #0973BA;
}

.toastify-icon {
    align-self: center;
}

.toastify-icon.toastify-close svg:hover {
    fill: #3F3B3B;
}

.toastify-icon-pad {
    padding-right: 12px;
}

.toastify-text {
    font-size: 16px;
    padding-right: 14px;
}

.ssn-login-container,
.qa-login-container,
.access-code-login-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: baseline;
}

.ssn-login-container .ssn-login-box,
.qa-login-container .qa-login-box,
.access-code-login-container .access-code-box {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
}

.ssn-login-container .ssn-input-box input::-ms-reveal,
.ssn-login-container .ssn-input-box input::-ms-clear {
    display: none;
}

.ssn-login-container {
    margin-top: 10%;
}

.qa-login-container {
    margin-top: 8%;
}

.access-code-login-container {
    margin-top: 5%;
}

.ssn-login-container .ssn-input-box input {
    width: 147px;
    height: 47px;
    border-radius: 2px !important;
    border: 1px solid #898D91;
    margin-top: 13%;
    margin-bottom: 2%;
    padding-right: 50%;
}

.ssn-login-container .ssn-button button,
.access-code-login-container .access-code-button button,
.qa-login-container .qa-login-button button {
    width: 250px;
    height: 56px;
    padding: 6px, 12px;
    border-radius: 200px !important;
    border: 1px;
    margin-top: 13%;
    background-color: #0973BA;
    font-size: 18px !important;
}

.ssn-login-container .ssn-button button[disabled],
.qa-login-container .qa-login-button button[disabled],
.access-code-login-container .access-code-button button[disabled] {
    background-color: #F0F1F1 !important;
    color: #C4C6C8 !important;
}

.access-code-login-container .otp-input-control-wrapper .input-container input {
    width: 34px !important;
    height: 41px !important;
    font-size: 32px !important;
    font-weight: 400 !important;
    color: #212529 !important;
    border-radius: 2px !important;
    border: 1px solid #6B7075 !important;
    background-color: #F5F6FB !important;
    margin-right: 16px !important;
    padding: 0;
    font-family: Mulish !important;
    font-style: normal;
    line-height: 120%;
}

.access-code-login-container .otp-input-control-wrapper.error .input-container input,
.qa-login-container .qa-login-input-box input.error,
.ssn-login-container .ssn-input-box input.error {
    border: 1px solid #CC4A43 !important;
}

.access-code-login-container .input-container {
    display: inline-block;
    position: relative;
    margin-top: 7%
}

.access-code-login-container .error-svg {
    position: absolute;
    right: -5%;
    top: 50%;
}

.access-code-login-container .otp-input-control-wrapper input:not(:last-child) {
    margin-right: 5px;
}

.ssn-login-container .ssn-input-box {
    position: relative;
}

.ssn-login-container .ssn-input-box .error-svg {
    position: absolute;
    right: 9%;
    top: 42%;
}

.qa-login-container .qa-login-input-box {
    position: relative;
    text-align: left;
}

.qa-login-container .qa-login-input-box .error-svg {
    position: absolute;
    right: 4%;
    top: 19%;
}

.qa-login-container .qa-login-input-box input {
    width: 360px;
    height: 42px;
    border-radius: 2px !important;
    border: 1px solid #898D91;
    margin-top: 2%;
    margin-bottom: 1%;
}

.qa-login-question {
    margin-top: 10%;
    width: 360px;
    text-align: left;
    padding-left: 3px;
}

#delegateButtonContainer {
    height: 72px;
    padding-top: 25px;
    padding-right: 35px;
}

.downloadAllButtonContainer {
    height: 72px;
    padding-top: 15px;
    padding-right: 20px;
    border-bottom: 0.25px solid #00000080;
}

#delegateButtonContainer button,
.downloadAllButtonContainer button {
    float: right;
    width: 150px;
    height: 38px;
    border-radius: 2px;
    border: 1px solid var(--theme-sapphire) !important;
    background-color: var(--HitBox) !important;
    color: var(--theme-sapphire) !important;
    box-shadow: none !important;
}

.downloadAllButtonContainer button {
    width: 160px;
}

.signingSuccessContainer,
.signingCompletedContainer,
.logoutSuccessContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signingSuccessContainer .signingSuccessBox,
.signingCompletedContainer .signingCompletedBox,
.logoutSuccessContainer .logoutSuccessBox {
    text-align: center;
}

.footer {
    position: fixed;
    bottom: 0;
    z-index: 10 !important;
    line-height: 220% !important;
}

.access-code-box .resendCodeButton {
    display: flex;
    justify-content: center;
    align-items: center;
}

.access-code-box .resendCodeButton>button {
    text-decoration: underline;
    cursor: pointer;
}

.logout-popover .modal-title,
.session-timeout-modal .modal-title {
    color: #FFFFFF !important;
    font-size: 20px !important;
    font-weight: 500 !important;
}

.logout-popover .modal-header,
.session-timeout-modal .modal-header {
    background-color: #05386B !important;
}

.session-timeout-modal .modal-content {
    border: 1px solid #00000033;
    border-radius: 4.8px !important;
}

.logout-popover .modal-header button span,
.session-timeout-modal .modal-header button span {
    color: #FFFFFF !important;
    font-weight: 100;
}

#btnCancelDecline {
    width: 165px;
}

.downloadContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    overflow-y: auto;
}

.downloadContainer .downloadBox {
    min-width: 800px;
    margin-top: 20px;
}

.downloadContainer .downloadBox .downloadListHeader {
    margin-top: 20px;
}

.downloadContainer .downloadBox .downloadListHeader>div {
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding-left: 0px !important;
}

.downloadContainer .downloadBox .downloadFileList {
    margin-top: 15px;
    border-bottom: 1px solid #C4C6C8;
    padding-bottom: 7px;
}

.downloadContainer .downloadBox .downloadFileList>div {
    display: inline-block;
    padding-left: 0px !important;
}

.downloadContainer .downloadBox .downloadFileList svg {
    margin-bottom: 5px;
    margin-right: 5px;
}

.downloadContainer .downloadBox .downloadFileList .download-button svg {
    margin-bottom: 3px;
    margin-right: 7px;
}

.downloadContainer .downloadBox .downloadFileList button {
    padding: 0px !important;
    justify-content: left;
    letter-spacing: 0.3px;
    min-height: 0px !important;
    border: 0px !important;
}

.downloadContainer .downloadBox .downloadFileList button.button-link:focus {
    box-shadow: none !important;
    border: 0px !important;
}

.downloadContainer .downloadBox .downloadFileList div:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: bottom;
    max-width: 400px;
}

.downloadContainer {
    padding-bottom: 100px;
}

.pdf-preview-container .left-panel span.rct-node-clickable:focus {
    outline: black auto 5px;
    outline-offset: 1px;
}